@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

* {
  font-family: "Outfit";
  --primary: #538cff;
  --secondary: #f7f7f7;
  /* overflow-x: hidden; */
}

.secondaryBg {
  background-color: #f7f7f7;
}

/* Navbar */

.firstText {
  color: var(--primary);
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  /* height: 100px; */
  padding: 11px 110px;
}

nav .title {
  margin: 1rem;
}

nav ul {
  display: flex;
  /* background-color: #F7F7F7; */
}

nav ul li {
  list-style: none;
}

nav ul li a {
  display: block;
  text-decoration: none;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  /* border-radius: 0.8rem; */
  font-weight: 600;
  font-size: 18px;
  line-height: 23.44px;
  color: #212121;
  border: 3px solid transparent;
}
nav ul li button {
  display: block;
  text-decoration: none;
  margin: 0 0.6rem;
}

nav ul li a:hover,
.active {
  /* background-color: var(--primary); */
  color: var(--primary);
  border-bottom: 3px solid var(--primary);
  /* border-radius: 20px; */
}

nav .menu {
  display: none;
  position: absolute;
  right: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 2rem;
}

nav .menu span {
  height: 0.4rem;
  width: 100%;
  border-radius: 0.2rem;
  background-color: black;
}

/* Hero */

.bgitem {
  position: absolute;
  left: -100px;
  top: -100px;
  width: 150px;
  object-fit: cover;
  animation: flowbg 5s ease-in-out forwards;
  animation-delay: 1s;
  /* z-index: 70; */
 }
@keyframes flowbg {
  0% {
    transform-origin: 200px -100px 0px 0px;
    transform: rotate(-290deg) scale(1);
  }
  /* 70% {
      transform-origin: 200px 00px 100% 100%;
      transform: rotate(-360deg) scale(14);
  } */
  100% {
    transform-origin: -200px -100px 100% 50%;
    transform: rotate(-360deg) scale(50);
  }
}
/* 
bgitem1 {
  /* position: absolute; */
  /* left: 1500px; */
  /* right: -40px;
  top: 60px;
  width: 5px;
  object-fit: cover;
  animation: flowbg1 5s ease-in-out forwards;
  animation-delay: 5s; */
  /* z-index: 70; */
  /* filter: invert(1) grayscale(1) ; }*/
 

/* @keyframes flowbg1 {
  0% {
    transform-origin: 0px 0px 0px 0px;
    transform: rotate(-420deg) scale(430);
  }

  /* 70% {
      transform-origin: 200px 00px 100% 100%;
      transform: rotate(-360deg) scale(14); 
  } */

  /* 100% {
    transform-origin: -200px -200px 0% 0%;
    transform: rotate(-480deg) scale(0);
  }
}  */
/* @media (min-width: 1024px) {
  @keyframes flowbg1 {
    0% {
      transform-origin: 200px -150px 0px 0px;
      transform: rotate(-360deg) scale(500); /* Larger scale for larger screens */
    /* }
    100% {
      transform-origin: -200px -200px 0% 0%;
      transform: rotate(-400deg) scale(0);
    }
  }
}  */

.color-change-animation {
  color: #ffffff; /* Initial color */
  animation: colorChange 1.2s 1.5s forwards; /* name duration delay animation-type*/
}

@keyframes colorChange {
  to {
    color: #0f0f0f; /* Final color */
  }
}

/* Expertise */

.expertiseCard {
  height: 380px;
  width: 98.3%;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0%;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
}

.bgGradient {
  box-shadow: 0 8px 6px 20px black;
  z-index: 3;
}

/* Slider */

/* Media Queries */

@media (max-width: 1150px) {
  nav {
    padding: 11px 50px;
  }
}

@media (max-width: 1150px) {
  nav {
    padding: 11px 20px;
  }
  nav ul li a {
    display: block;
    text-decoration: none;
    padding: 0.5rem 0.6rem;
    margin: 0 0rem;
    /* border-radius: 0.8rem; */
    font-weight: 600;
    font-size: 18px;
    line-height: 23.44px;
    color: #212121;
    border: 3px solid transparent;
  }
}

@media (max-width: 821px) {
  nav .menu {
    display: flex;
  }

  nav {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 80px;
  }
  nav .title {
    margin-left: 20px;
  }

  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.25rem;
  }

  nav ul.open {
    display: flex;
    position: absolute;
    top: 100px;
    border-radius: 0px;
    left: 0;
    right: 0;
    text-align: center;
    justify-content: center;
    padding: 10px 0px;
  }

  nav ul li a {
    margin: 0.2rem 0.3rem;
  }

  .bgitem {
    animation: flowbg 5.4s ease-in-out forwards;
  }
  .color-change-animation {
    color: #ffffff; /* Initial color */
    animation: colorChange 1.2s 1.1s forwards; /* name duration delay animation-type*/
  }
}

@media (max-width: 480px) {
  .bgitem {
    animation: flowbg 4s ease-in-out forwards;
    animation-delay: 1.5s;
  }

  .color-change-animation {
    color: #ffffff; /* Initial color */
    animation: colorChange 1.2s 1.9s forwards; /* name duration delay animation-type*/
  }
}
