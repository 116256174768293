.text-reverse-h {
  transform: scaleX(-1);
}

/* src/App.css */
@keyframes color {
  0% {
    filter: grayscale(200%);
  }
  100% {
    filter: grayscale(0%);
  }
}

.animate-color {
  animation: color 12s forwards;
}

/* .slider-rtl .slick-list {
  direction: rtl;
} */

.slider-rtl .slick-slide {
  transform: scaleX(-1);
}

.slider-rtl .slick-slide img {
  transform: scaleX(-1);
}

.slick-arrow.slick-next {
  display: none;
}

.slick-arrow.slick-prev {
  display: none;
}

.worldBGImg {
  background: linear-gradient(180deg, #f9f9f9 0.1%, rgba(249, 249, 249, 0) 100%),
    url(../../assets/img/world.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
}

.sliderImageContainer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 10vh !important;
}




.custom-select {
  background-color: #5A91FF; /* Background color of select tag */
  color: white;
}

.custom-select option {
  background-color: white; /* Background color of dropdown options */
  color: #5A91FF; /* Text color of dropdown options */
}

.custom-select:focus {
  outline: none;
  border-color: transparent;
}

.custom-select option:hover,
.custom-select option:focus {
  background-color: #5A91FF; /* Hover background color */
  color: white; /* Hover text color */
}


@media (max-width: 600px) {
  .sliderImageContainer {
    height: 7vh !important;
  }
}