.phoneInputContainer input {
  border: none;
  background-color: #5a91ff;
  color: #ffffff;
}

.phoneInputContainer {
  background-color: #5a91ff;
  width: 100%;
  padding: 18px 14px;
  border-bottom: 2px solid #ffffff;

  /* border-radius: 6px; */
  margin-top: -5px;

  border-radius: 6px;
  margin-top: -6px;

}

.phoneInputContainer input:active,
.phoneInputContainer input:focus {
  outline: none;
  border: none;
}

.phoneInputContainer input::placeholder {
  color: #ffffff !important;
}

.phoneInputContainer input {
  color: #ffffff !important;
  font-size: 18px;
}


@media (min-width: 768px) and (max-width: 1024px) {
  .phoneInputContainer {
    padding: 14px 14px;
  }
}
